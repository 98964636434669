/**
 * Analytics library for managing data layer events passed to GTM.
 * Correct usage is to call enable_gtm() first, then enable other desired features,
 * and finally use event() methods to create events.
 */
// create data layer objects
window.dataLayer = window.dataLayer || [];

// for legacy compatibility
window.gtag = function () {
    analytics.gtag(arguments);
}

window.analytics = (function (win) {

    // constants
    const PUSH_TIMEOUT = 2000;

    // runtime params
    let _log_to_console = false;
    let _google_enabled = false;
    let _amplitude_enabled = false;
    let _adwords_enabled = false;
    let _gtm_enabled = false;
    let _brand = "";
    let _user_props = {};
    let _warn_unpushed = false;

    // external vars
    let _dataLayer = win.dataLayer;

    /**
     * Log error message to console, if logging is enabled.
     * @param msg one or more objects
     * @private
     */
    function _err(...msg) {
        if (_log_to_console) {
            console.error(...msg);
        }
    }

    /**
     * Log a message to console, if logging is enabled.
     * @param msg one or more objects
     * @private
     */
    function _log(...msg) {
        if (_log_to_console) {
            console.log(...msg);
        }
    }

    /**
     * Verifies and modifies a SKU so that it conforms to expected data layer format. Any validation errors
     * will only be emitted to console log.
     * @param product an object
     * @param isVirtual True if these are virtual products
     * @returns updated product
     * @private
     */
    function _prepare_product(product, isVirtual) {
        if (typeof product === "object") {
            let new_product = Object.assign({}, product);
            // check for required SKU properties
            if (!new_product.hasOwnProperty("sku")) {
                _err('analytics._prepare_product: missing SKU / product ID');
            }
            for (let property of Array("name", "price", "category", "local_currency_code")) {
                if (!new_product.hasOwnProperty(property)) {
                    _err("analytics._prepare_product: missing " + property);
                }
            }
            if (!isVirtual && !new_product.hasOwnProperty("local_price")) {
                _err("analytics._prepare_product: missing local_price");
            }
            // add brand if not specified
            if (!new_product.hasOwnProperty("brand")) {
                if (_brand === "") {
                    _err("analytics._prepare_product: missing brand, and no default brand was set");
                }
                new_product["brand"] = _brand;
            }
            // set quantity to 1 if not specified
            if (!new_product.hasOwnProperty("quantity")) {
                new_product["quantity"] = 1;
            }
            // set URL if not specified
            if (!new_product.hasOwnProperty("url")) {
                new_product["url"] = win.location.origin + win.location.pathname;
            }
            return new_product;
        }
        return {};
    }

    /**
     * Maps product collection to format for GA.
     * @param products an array of products
     * @returns array
     * @private
     */
    function _ga_products(products) {
        return products.map(product => {
            return {
                "item_id": product["sku"],
                "item_name": product["name"],
                "item_brand": product["brand"],
                "item_category": product["category"],
                "price": product["price"],
                "local_price": product["local_price"],
                "local_currency_code": product["local_currency_code"],
                "quantity": product["quantity"]
            };
        });
    }

    /**
     * Maps virtual currency product collection to format for Amplitude.
     * @param products an array of products
     * @returns array
     * @private
     */
    function _vc_products(products) {
        return products.map(product => {
            return {
                "vc_id": product["sku"],
                "vc_name": product["name"],
                "vc_brand": product["brand"],
                "vc_category": product["category"],
                "vc_price": product["price"],
                "vc_quantity": product["quantity"],
                "vc_url": product["url"],
                "vc_currency_code": product["local_currency_code"]
            };
        });
    }

    /**
     * Used by AnalyticsEvent.products() and AnalyticsEvent.vc_products() to add
     * one or more products to a collection.
     * @param products A SKU object or an array of SKU objects.
     * @param collection The array to add products to.
     * @param isVirtual True if these are virtual products.
     * @private
     */
    function _add_products_to_collection(products, collection, isVirtual) {
        if (Array.isArray(products)) {
            for (let product of products) {
                collection.push(_prepare_product(product, isVirtual));
            }
        } else if (typeof products === "object") {
            collection.push(_prepare_product(products, isVirtual));
        } else {
            _err('analytics._add_products_to_collection: provided products were neither an array nor an object');
        }
    }

    /**
     * After calling, further event tracking will be pushed to Amplitude.
     */
    function enable_amplitude() {
        if (!_amplitude_enabled) {
            _amplitude_enabled = true;
            _log("analytics: amplitude enabled");
            if (!_gtm_enabled) {
                _err("analytics.enable_amplitude: GTM not enabled, nothing will be sent");
            }
        }
    }

    /**
     * After calling, futher event tracking will be pushed to Google Analytics.
     */
    function enable_google_analytics() {
        if (!_google_enabled) {
            _google_enabled = true;
            _log("analytics: GA enabled");
            if (!_gtm_enabled) {
                _err("analytics.enable_google_analytics: GTM not enabled, nothing will be sent");
            }
        }
    }

    /**
     * Create a GTM container. Typically you want to do this before setting up anything else.
     * @param gtm_container_id container ID, required
     * @param gtm_domain domain name to use for GTM calls, e.g. metrics.warframe.com
     * @param gtm_args Additional URL params to pass to GTM (must start with '&' if specified), optional
     */
    function enable_gtm(gtm_container_id, gtm_domain, gtm_args) {
        if (!_gtm_enabled) {
            _gtm_enabled = true;
            _log("analytics.enable_gtm: creating container for ", gtm_container_id, "(args:", gtm_args, ")");
            // GTM code snippet - do not change
            gtm_args = gtm_args || '';
            (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
                let f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src =
                    `https://${gtm_domain}/gtm.js?id=` + i + dl + gtm_args;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', gtm_container_id);
            // end GTM code snippet
        }
    }

    /**
     * Enables Google AdWords tracking.
     * @param adwords_id adwords ID
     * @param language user's current language, 2-letter ISO code
     * @param auth_status true if user is logged in, otherwise false
     */
    function enable_google_adwords(adwords_id, language, auth_status) {
        if (!_adwords_enabled) {
            _adwords_enabled = true;
            _log("analytics.enable_google_adwords(", adwords_id, ",", language, ",", auth_status, ")");
            const adwords = {
                "js": new Date(),
                "config": adwords_id,
                "language": language,
                "authenticationStatus": !!auth_status ? "1" : "0"
            };
            _dataLayer.push(adwords);
            if (!_gtm_enabled) {
                _err("analytics.enable_google_adwords: GTM not enabled, nothing will be sent");
            }
        }
    }

    /**
     * Used to assemble individual events to push to the dataLayer.
     * @see event()
     */
    class AnalyticsEvent {
        /**
         * Create a new AnalyticsEvent for the specified event name.
         * @param event_name string
         */
        constructor(event_name) {
            this.pushed = false;
            this.event_name = event_name;
            this.event_properties = {};
            this.legacy_event_name = false;
            this.event_products = [];
            this.event_vc_products = [];
            if (_warn_unpushed) {
                win.setTimeout((evt) => {
                    if (!evt.pushed) {
                        // intentionally does not use _err()
                        console.error("AnalyticsEvent:", evt.event_name, "|", evt.legacy_event_name, "created but not pushed");
                    }
                }, PUSH_TIMEOUT, this);
            }
        }

        /**
         * If specified, this event will also be sent to Google Analytics using
         * the specified event name.
         * @param event_name string
         * @returns {AnalyticsEvent}
         */
        legacy(event_name) {
            if (typeof event_name === "undefined" || !!event_name === false) {
                _err('analytics.AnalyticsEvent.legacy: event_name is required');
            } else {
                this.legacy_event_name = event_name;
            }
            return this;
        }

        /**
         * Add a dictionary of properties to this event. This can be called multiple
         * times to add more properties.
         * @param event_properties a set of key/value pairs (JS object)
         * @returns {AnalyticsEvent}
         */
        properties(event_properties) {
            if (typeof event_properties === "object") {
                for (const [key, value] of Object.entries(event_properties)) {
                    this.event_properties[key] = value;
                }
            } else {
                _err('analytics.AnalyticsEvent.properties: expected an Object');
            }
            return this;
        }

        /**
         * Add real money products to this event. This can be called multiple times
         * to add more products.
         *
         * Note: If you add products, make sure to include the currency_code property on the event.
         * @param products An array of SKU objects.
         * @returns {AnalyticsEvent}
         */
        products(products) {
            _add_products_to_collection(products, this.event_products, false);
            return this;
        }

        /**
         * Add virtual currency products to this event. This can be called multiple times
         * to add more products.
         *
         * Note: If you add VC products, make sure to include game_platform
         * and currency_code properties on the event.
         * @param products A SKU object or an array of SKU objects.
         * @returns {AnalyticsEvent}
         */
        vc_products(products) {
            _add_products_to_collection(products, this.event_vc_products, true);
            return this;
        }

        /**
         * Finalize this event and add it to the data layer.
         */
        push() {
            // pushed already?
            if (this.pushed) {
                _err("analytics.AnalyticsEvent.push: already sent");
                return;
            }

            // create GA data layer entry
            if (_google_enabled && !!this.legacy_event_name !== false) {
                let ga_event = Object.assign({}, this.event_properties);
                ga_event["event"] = this.legacy_event_name;
                let list_id = window.location.pathname.split('/');
                list_id = list_id[list_id.length - 1];
                if (this.event_products.length > 0) {
                    ga_event["currency"] = "USD";
                    ga_event["ecommerce"] = {
                        "items": _ga_products(this.event_products),
                        "item_list_id": list_id
                    };
                }
                if (this.event_vc_products.length > 0) {
                    ga_event["vc_items"] = _vc_products(this.event_vc_products);
                    ga_event["vc_item_list_id"] = list_id;
                }
                _dataLayer.push(ga_event);
                _log("Pushed to Google:", ga_event);
            }

            // create Amplitude data layer entry
            if (_amplitude_enabled && !!this.event_name !== false) {
                let amp_event = Object.assign({}, this.event_properties);
                amp_event["event"] = this.event_name;
                if (this.event_products.length > 0) {
                    amp_event["products"] = this.event_products;
                }
                if (this.event_vc_products.length > 0) {
                    amp_event["vc_products"] = _vc_products(this.event_vc_products);
                }
                _dataLayer.push(amp_event);
                _log("Pushed to Amplitude:", amp_event);
            }

            // warn if GTM wasn't setup
            if (!_gtm_enabled) {
                _err("analytics.AnalyticsEvent.push: GTM not enabled, nothing will be sent");
            }

            // entry has been pushed
            this.pushed = true;
        }
    }

    /**
     * Prepares an event for the data layer. Returns an object where you can add properties
     * and products. Finalize by calling push().
     * @param event_name The event name, required.
     * @throws if event_name isn't a valid non-empty string
     * @return {AnalyticsEvent}
     */
    function event(event_name) {
        if (typeof event_name !== "string" || !!event_name === false) {
            _err("analytics.event: event_name is required");
            throw "analytics.event: event_name is required";
        }
        return new AnalyticsEvent(event_name);
    }

    /**
     * Prepares a Google Analytics-only event for the data layer. Returns an object where you can
     * add properties and products. Finalize by calling push().
     * @param event_name The event name, required.
     * @deprecated Don't use unless you know what you're doing.
     */
    function legacy(event_name) {
        _err('analytics.legacy: deprecated');
        if (typeof event_name !== "string" || !!event_name === false) {
            _err("analytics.legacy: event_name is required");
            throw "analytics.legacy: event_name is required";
        }
        return new AnalyticsEvent(false).legacy(event_name);
        s
    }

    /**
     * Immediately pushes a UA-style event.
     * @param event_name The event name, required.
     * @param event_category The event category, optional.
     * @param event_action The event action, optional.
     * @param event_label The event label, optional.
     * @throws if event_name isn't a valid non-empty string
     * @deprecated Don't use unless you know what you're doing.
     */
    function ua(event_name, event_category = "", event_action = "", event_label = "") {
        _err('analytics.ua: deprecated');
        if (typeof event_name !== "string" || !!event_name === false) {
            _err("analytics.ua: event_name is required");
            throw "analytics.ua: event_name is required"
        }
        if (_google_enabled) {
            _dataLayer.push({
                "event": event_name,
                "eventCategory": event_category,
                "eventAction": event_action,
                "eventLabel": event_label,
            });
        }
    }

    /**
     * Set the default brand (used with product-based events).
     * @param new_value new brand, must be a non-empty string
     * @throws if new_value isn't a valid non-empty string
     */
    function set_brand(new_value) {
        if (typeof new_value !== "string" || !!new_value === false) {
            _err("analytics.set_brand: new_value is required");
            throw "analytics.set_brand: new_value is required";
        }
        _brand = new_value;
        _log("analytics.set_brand:", new_value);
    }

    /**
     * Set whether or not to log additional info to the browser console.
     * @param new_value bool
     */
    function set_log_to_console(new_value) {
        _log_to_console = !!new_value;
    }

    /**
     * If set to true, the library will attempt to warn when an event
     * has been created but not pushed. (This will log to console
     * even if logging is not enabled.)
     *
     * Warning: This creates lots of JS timers, don't leave on.
     * @param new_value bool
     */
    function set_warn_unpushed_events(new_value) {
        _warn_unpushed = !!new_value;
    }

    /**
     * Legacy gtag() support -- forwards args directly to dataLayer.
     * @deprecated
     */
    function gtag() {
        _err('analytics.gtag: deprecated');
        _dataLayer.push(arguments);
    }

    /**
     * Adds or updates user properties for use with GTM. The analytics library itself
     * does not inspect these properties; this a convenience function for passing data
     * to the GTM container.
     * @param properties An object containing key/value pairs.
     * @see get_user_property
     * @see get_user_properties
     */
    function set_user_properties(properties) {
        for (const [key, value] of Object.entries(properties)) {
            if (_user_props.hasOwnProperty(key)) {
                _log("analytics.set_user_properties: updating", key, _user_props[key], "=>", value);
            } else {
                _log("analytics.set_user_properties: adding", key, "(new) =>", value);
            }
            _user_props[key] = value;
        }
    }

    /**
     * Gets the current set of user properties.
     * @returns An object containing key/value pairs.
     * @see get_user_property
     * @see set_user_properties
     */
    function get_user_properties()
    {
        return Object.assign({}, _user_props);
    }

    /**
     * Gets a specific user property, if it exists.
     * @param key The key name of the property to retrieve.
     * @return The value of the property specified by key if it exists; otherwise, undefined.
     */
    function get_user_property(key)
    {
        if (_user_props.hasOwnProperty(key))
        {
            return _user_props[key];
        }
        // _log instead of _err here since it might be expected
        _log("analytics.get_user_property:", key, "is not a defined user property");
        return undefined;
    }

    return {
        "enable_amplitude": enable_amplitude,
        "enable_google_analytics": enable_google_analytics,
        "enable_gtm": enable_gtm,
        "enable_google_adwords": enable_google_adwords,
        "event": event,
        "get_user_properties": get_user_properties,
        "get_user_property": get_user_property,
        "gtag": gtag,
        "legacy": legacy,
        "set_brand": set_brand,
        "set_log_to_console": set_log_to_console,
        "set_user_properties": set_user_properties,
        "ua": ua,
        "set_warn_unpushed_events": set_warn_unpushed_events,
    };
})(window);
